<template>
	<Header ref="Header"></Header>
	<div class="messageBox">
		<div class="message" style="min-height:900px">
			<div class="tabBox">
				<div class="tab" :class="type==0?'cur':''" @click="switchTab(0)">
					所有
				</div>
				<div class="tab" :class="type==1?'cur':''" @click="switchTab(1)">
					未读
					<el-tooltip
						class="box-item"
						effect="dark"
						content="清空未读信息"
						placement="top"
					>	
						<el-icon :size="20" @click.stop="BrushBtn"><Brush/></el-icon>
					</el-tooltip>
				</div>
			</div>
			<List ref="List" :ssId="ssId" v-if="type==0"></List>
			<List ref="List" :ssId="ssId" v-if="type==1"></List>
		</div>
	</div>
	<Footer></Footer>
</template>

<script>
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { Brush } from '@element-plus/icons-vue'
	import List from "./List/List"
	export default {
		components:{ Header,Footer,List,Brush },
		data() {
			return{
				type:0,
				ssId:'',
			}
		},
		created() {
			this.setHeight();
		},
		methods: {
			BrushBtn(){
				let that = this;
				this.$axios({
					method:'post',
					url:'user/me/setReadAll',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					that.$refs.List.rows = [];
					that.$refs.List.initData();
					that.$refs.Header.getTotalMessage(window.localStorage.getItem("urId"))
				})
				.catch((err)=>console.log('请求失败',err))
			},
			switchTab(index){
				if(index==0){
					this.ssId = "";
				}else{
					this.ssId = "user_message_0";
				}
				this.type = index;
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 237;
			}
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	}
</script>

<style scoped>
	.el-icon{
		transform: rotate(180deg);
		font-weight:bold;
	}
	.tabBox{
		/* width:620px !important;
		margin:0px auto; */
		padding-top:20px;
	}
	.tab{
		display: inline-block;
		padding:10px 20px;
		color:#999;
		font-size:18px;
	}
	.cur{
		border-bottom:2px solid #409eff;
		color:#409eff;
	}
	.messageBox{
		background:#e8e8e8;
	}
	.message{
		width:1240px;
		height:auto;
		margin:0px auto;
		padding-bottom: 20px;
	}
</style>
