<template>
	<div class="ListBox">
		<div class="list" v-for="item in rows" :key="item.meId">
			<div class="title">
				{{item.meText}}
				<span v-if="item.ssId=='user_message_0'"></span>
			</div>
			<div class="time">{{item.meTime}}</div>
		</div>
	</div>
</template>

<script>
	import { ElLoading } from 'element-plus'
	export default {
		props:['ssId'],
		components:{ ElLoading },
		data() {
			return{
				rows:[],
				currentPage: 1,
				total: 0,
				pageSize:20,
			}
		},
		created() {
			let that = this;
			that.initData();
			that.$nextTick(()=>{
				window.onscroll = function(){
					var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
					if(scrollTop+windowHeight==scrollHeight){
						if(that.rows.length < that.total) {
							that.currentPage++;
							that.initData();
						}
					} 
				}
			})
		},
		methods: {
			initData(){
				let that = this;
				let loading = ElLoading.service({
					lock: true,
					text: '正在加载',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				this.$axios({
					method:'post',
					url:'user/me/getList',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId:window.localStorage.getItem("urId"),
						ssId: that.ssId,
						page: that.currentPage,
						limit: that.pageSize
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.rows = that.rows.concat(rows);
					that.total = res.data.totalCounts;
					loading.close();
				})
				.catch((err)=>console.log('请求失败',err))
			}
		},
	}
</script>

<style scoped>
	.ListBox{
		width:620px;
		margin-top:20px;
	}
	.list{
		padding:15px;
		background:#fff;
		border-radius: 15px;
		margin-bottom:15px;
	}
	.title{
		font-size: 16px;
		font-weight:bold;
		line-height: 25px;
	}
	.title span{
		width:10px;
		height:10px;
		background:red;
		border-radius:50%;
		display: inline-block;
	}
	.time{
		margin-top:10px;
		color:#9c9c9c;
		text-align: right;
		font-size:13px;
	}
</style>
